import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Seo from '../components/seo'
import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />
        { page.acf.components && page.acf.components.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'page'))  }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
        schema {
          raw
        }
      }
      acf {
        components {
          ... on WpPage_Acf_Components_OurExperts {
            fieldGroupName
            teamMembers {
              ... on WpTeam {
                title
                content
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 75
                          width: 1200
                          formats: [AUTO, WEBP, AVIF]
                          placeholder: DOMINANT_COLOR
                        )
                      }
                    }
                  }
                }
                locations {
                  nodes {
                    id
                    slug
                  }
                }
                acf {
                  shortBio
                  name {
                    first
                    last
                  }
                  role
                  email {
                    link
                    text
                  }
                  phone {
                    link
                    text
                  }
                  linkedin {
                    link
                    text
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Components_InformativeBlocks {
            fieldGroupName
            items {
              title
              content
              text
              link
            }
          }
          ... on WpPage_Acf_Components_BasicPage {
            fieldGroupName
            title
            subTitle
          }
          ... on WpPage_Acf_Components_CareerBlocks {
            fieldGroupName
            items {
              title
              content
            }
          }
          ... on WpPage_Acf_Components_Partners {
            fieldGroupName
            title
            items {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Components_MarketingInsights {
            fieldGroupName
            items {
              title
              content
              pdf {
                mediaItemUrl
                localFile {
                  publicURL
                }
              }
            }
          }
          ... on WpPage_Acf_Components_TabsMenu {
            fieldGroupName
            items {
              text
              link
              active
            }
          }
          ... on WpPage_Acf_Components_Landing {
            fieldGroupName
            title
            text
          }
        }
      }
    }
  }
`

export default PageTemplate
